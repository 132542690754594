import { Link } from 'react-router-dom';
import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';
import { NetworkType } from '../PackageOrders/types';
import PackageOrdersActions from './actions';

import AutomaticPayout from './actions/AutomaticPayout';
import { getUserFromLS } from '../../../helpers/localstorage-helper';

import { useAppSelector } from '../../../app/hooks';

const userFromLS = getUserFromLS();

const permissions = userFromLS?.permissions;

export const columns = [
  {
    id: 1,
    Header: 'Withdrawal ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'user',
    Cell: (cell: any) => {
      if (cell?.value?.isBlacklisted) {
        return (
          <Link className='text-secondary' to={`/users/${cell?.value?.id}`}>
            <div style={{ color: 'red' }}>{`! ${cell?.value?.id}`}</div>
          </Link>
        );
      } else {
        return (
          <Link className='text-secondary' to={`/users/${cell?.value?.id}`}>
            {cell?.value?.id}
          </Link>
        );
      }
    }
  },
  {
    id: 3,
    Header: 'User',
    accessor: 'user',
    Cell: (cell: any) => {
      return (
        <>
          {cell.value.name} {cell.value.surname}
        </>
      );
    }
  },

  {
    id: 4,
    Header: 'Wallet Address',
    accessor: 'userWallet',
    Cell: (cell: any) => {
      if (cell?.value?.isBlacklisted) {
        return (
          <div style={{ color: 'red' }}>{`! ${cell?.value?.address}`}</div>
        );
      } else if (cell.row.original.shouldFlagForSpam) {
        return (
          <div className='text-warning'>{`! ${cell?.value?.address}`}</div>
        );
      } else {
        return cell?.value?.address;
      }
    }
  },
  {
    id: 5,
    Header: 'Network type',
    accessor: 'networkType',
    Cell: (cell: any) => {
      switch (cell.value) {
        case NetworkType.BEP_20:
          return <div>BEP-20</div>;

        case NetworkType.TRC_20:
          return <div>TRC-20</div>;

        case NetworkType.Ultron:
          return <div>Ultron</div>;

        default:
          return <div></div>;
      }
    }
  },
  {
    id: 6,
    Header: 'Tooken type',
    accessor: 'networkCurrencyType',
    Cell: (cell: any) => {
      return cell?.value;
    }
  },
  {
    id: 7,
    Header: 'Amount',
    accessor: 'paidAmount',
    Cell: (cell: any) => {
      return cell?.value ? usdFormatter.format(cell?.value) : '---';
    }
  },

  {
    id: 8,
    Header: 'Fee',
    accessor: 'fee',
    Cell: (cell: any) => {
      return usdFormatter.format(cell.row.original.feeAmount);
    }
  },

  {
    id: 9,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },

  {
    id: 10,
    Header: 'Date Paid',
    accessor: 'datePaid',
    Cell: (cell: any) => (
      <>
        {cell.value ? (
          <>
            {' '}
            {handleValidDate(cell.value)},
            <small className='text-muted'> {handleValidTime(cell.value)}</small>
          </>
        ) : (
          '---'
        )}
      </>
    )
  },

  {
    id: 11,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell.value) {
        case 1:
          return (
            <span className='badge text-uppercase badge-soft-warning'>
              Pending
            </span>
          );

        case 2:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Paid
            </span>
          );

        case 3:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Completed
            </span>
          );

        case 4:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              Canceled
            </span>
          );

        case 5:
          return (
            <span className='badge text-uppercase badge-soft-info'>
              Automatic payout in progress
            </span>
          );

        case 6:
          return (
            <span className='badge text-uppercase badge-soft-success'>
              Automatic payout paid
            </span>
          );

        default:
          return (
            <span className='badge text-uppercase badge-soft-danger'>
              Not paid
            </span>
          );
      }
    }
  },

  {
    id: 12,
    Header: permissions?.withdrawals ? 'Actions' : '',
    accessor: 'id',
    Cell: (cell: any) => {
      return <PackageOrdersActions {...cell} />;
    }
  },
  {
    id: 13,
    Header: () => {
      const { showActionButtons } = useAppSelector(
        (state) => state.withdrawals
      );
      return showActionButtons ? 'Multiple payouts' : '';
    },

    accessor: 'checked',

    Cell: (cell: any) => {
      const { showActionButtons } = useAppSelector(
        (state) => state.withdrawals
      );
      return showActionButtons ? <AutomaticPayout {...cell} /> : <div></div>;
    }
  }
];
