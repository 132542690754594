import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';

import TableContainer from '../../../../../Components/Common/TableContainer';

import { columns } from './columns';
import { useMatch } from 'react-router';
import { getWithdrawals } from '../../slice';

export const Withdrawals: React.FC = () => {
  const { withdrawals } = useAppSelector((state) => state.userDetails);

  const dispatch = useAppDispatch();

  const match = useMatch('/users/:id/*');
  const userId = match?.params.id;

  useEffect(() => {
    dispatch(getWithdrawals(Number(userId)));
  }, [dispatch, dispatch]);

  return (
    <>
      <TableContainer
        columns={columns}
        data={withdrawals || []}
        divClass='table-responsive table-card mb-1'
        tableClass='align-middle table-nowrap'
        theadClass='table-light text-muted'
      />
    </>
  );
};
