import moment from 'moment';
import {
  handleValidDate,
  handleValidTime,
  formatUSD
} from '../../../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'Date',
    accessor: 'date',
    Cell: (cell: any) => {
      return (
        <div>
          {moment.utc(cell?.row?.original.dateCreated).format('DD/MM/YY')}
        </div>
      );
    }
  },
  {
    id: 2,
    Header: 'ID',
    accessor: 'id',
    Cell: (cell: any) => <div>#{cell?.row?.original?.id}</div>
  },
  {
    id: 3,
    Header: 'Amount',
    accessor: 'amount',
    Cell: (cell: any) => (
      <div>
        {formatUSD(cell?.row?.original?.amount)}{' '}
        {cell?.row?.original?.networkCurrencyType?.toUpperCase()}
      </div>
    )
  },
  {
    id: 4,
    Header: 'Fee',
    accessor: 'feeAmount',
    Cell: (cell: any) => (
      <div>
        {formatUSD(cell?.row?.original?.feeAmount)}{' '}
        {cell?.row?.original?.networkCurrencyType?.toUpperCase()}
      </div>
    )
  },
  {
    id: 5,
    Header: 'Paid Amount',
    accessor: 'paidAmount',
    Cell: (cell: any) => (
      <div>
        {formatUSD(cell?.row?.original?.paidAmount)}{' '}
        {cell?.row?.original?.networkCurrencyType?.toUpperCase()}
      </div>
    )
  },
  {
    id: 6,
    Header: 'Wallet',
    accessor: 'wallet',
    Cell: (cell: any) => <div>{cell?.row?.original?.wallet}</div>
  },
  {
    id: 7,
    Header: 'Status',
    accessor: 'status',
    Cell: (cell: any) => {
      switch (cell?.row?.original?.status) {
        case 1:
          return 'pending';
        case 2:
          return 'paid';
        case 3:
          return 'completed';
        case 4:
          return 'canceled';
        case 5:
          return 'automatic payout in progress';
        case 6:
          return 'paid';
        default:
      }
    }
  }
];
