export interface Withdrawals {
  id: number;
  paidAmount: number;
  dateCreated: string;
  datePaid: string;
  status: WithdrawalStatusEnum;
  networkType: number;
  networkCurrencyType: string;
  type: number;
  isPayoutSuccessful: boolean;
  user: {
    id: number;
    email: string | null;
    profileImageUrl: string | null;
    name: string | null;
    surname: string | null;
    dateCreated: string;
  };
  userWallet: {
    id: number;
    address: string;
    dateCreated: string;
    status: number;
    label: string | null;
  };
  checked: boolean;
}

export enum WithdrawalStatusEnum {
  PENDING = 1,
  PAID,
  COMPLETED,
  CANCELED,
  AUTOMATIC_PAYOUT_IN_PROGRESS,
  AUTOMATIC_PAYOUT_PAID
}

export enum WithdrawalTypeEnum {
  WALLET = 1,
  EXCHANGE
}

export enum OPENED_MODAL {
  CANCELED = 1,
  MARK_AS_PAID,
  AUTOMATIC_PAYOUT
}
